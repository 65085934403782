import {inject } from 'aurelia-framework';
import {LoginService} from '../../shared/services/loginservice';
import {DashboardService} from '../../shared/services/dashboardservice';
import {Router} from 'aurelia-router';
import {JwtService} from '../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {SidebarService} from "../../shared/services/sidebarservice";
import {config} from "../../shared/services/config";
import { constants } from '../../shared/services/constants';
import { App } from '../../app';
import { MsalService } from '../../shared/services/msalService';
import {ProfileService} from "../../shared/services/profielservice";

@inject(ProfileService,LoginService,Router,DashboardService,JwtService,SidebarService,MsalService,App)
export class Sidebarcomponent {
  dynamicmenu = '';
  LoadFavourites=[];
  LoadRecentItems=[];
  showTeamFunctions = false;
  showTeamFunctionsMenu = false;
  Permanentempclass = constants.permanentEmployeeClassID;
  constructor(ProfileService,loginService,router,dashboardService,jwtService,sidebarService,MsalService,App){
    this.loginService= loginService;
    this.router = router;
    this.dashboardService = dashboardService;
    this.jwtService = jwtService;
    this.sidebarService=sidebarService;
    this.msalService = MsalService
    this.app = App;
    this.employeeId = this.jwtService.getEmployeeId();
    this.profileService = ProfileService;
  }

  async bind(){
    setTimeout(()=>{
      Scrollbar.init(document.querySelector('.sidebar'));
    },100);
    const empid = this.jwtService.getEmployeeId();
    const span = await document.querySelectorAll('.close');
        for(let i =0; i < span.length; i++) {
            span[i].onclick = function () {
                let params=span[i].attributes[1].value;
                let employeeId = empid;//params.replace(/^\D+|\D.*$/g, "");
                let favoriteId = params.replace(/.*\D(?=\d)|\D+$/g, "");
                this.removeFromFavorites(employeeId,favoriteId);
                span[i].parentNode.remove();
            };
        }
  }

   removeFromFavorites(employeeId,favoriteId){
    return this.sidebarService.DeleteFavoriteItems(this.employeeId,favoriteId).then(() =>{
      this.dashboardService.GetAllFavorites(this.employeeId)
      .then(data => {     
          this.LoadFavourites = data;
          window.location.reload();
      });
    });
}
  attached() {
      // if(this.jwtService.getToken() === undefined){
      //     this.loginService.purgeAuth();
      //     this.router.navigateToRoute('login')
      // }
      this.empClass = this.jwtService.getEmployeeClass();
      this.service = this.jwtService.getService();
      if(this.empClass != this.Permanentempclass){
        document.getElementById("liattend").children[0].style.display = "none";
        document.getElementById("liasset").children[0].style.display = "none";

      }else{
        document.getElementById("liattend").children[0].style.display = "block";
        document.getElementById("liasset").children[0].style.display = "block";
      }
    const  empid = this.jwtService.getEmployeeId();
      this.dashboardService.GetAllFavorites(empid)
    .then(data => {
        this.LoadFavourites = data;
    });
    this.dashboardService.GetRecentItems(empid)
    .then(data => {
        this.LoadRecentItems = data;
    });
      this.profileService.EmployeeDetails(empid).then(data => {
          this.generalInformation=data;
          // Check if the role names include only "Employee"
          if (this.generalInformation.RoleNames.length <= 9) {
              this.showTeamFunctionsMenu = false; // Hide the "Team Functions" menu item
          } else {
              this.showTeamFunctionsMenu = true; // Show the "Team Functions" menu item
          }
      });


    this.getCurrentRouteURL();

    const tags = document.querySelectorAll('.sidebar-sub li a');
    const storedIndex = localStorage.getItem('activeTagIndex');
    if (storedIndex !== null && tags[storedIndex]) {
        tags[storedIndex].classList.add('active-link');
    }
    tags.forEach((tag, index) => {
        tag.addEventListener('click', function() {
            tags.forEach(t => {
                t.classList.remove('active-link');
            });
            this.classList.add('active-link');
            localStorage.setItem('activeTagIndex', index);
        });
    });
    const activeLink = document.querySelector(".active-link");
    if (activeLink) {
      const parentLi = activeLink.parentNode.parentNode.parentNode;
      if (parentLi && parentLi.tagName === "LI") {
        parentLi.classList.add("sub-active");
      }
    }
    var sidebarLinks = document.querySelectorAll(".sidebar ul li.home a, .sidebar ul li.support a, .sidebar ul li.logout a");
    sidebarLinks.forEach(function(link) {
      link.addEventListener("click", function(event) {
        event.preventDefault();
        var elementsWithSubActive = document.querySelectorAll(".sub-active");
        elementsWithSubActive.forEach(function(element) {
          element.classList.remove("sub-active");
        });
        var elementsWithSubActive1 = document.querySelectorAll(".active-link");
        elementsWithSubActive1.forEach(function(element) {
          element.classList.remove("active-link");
        });
      });
    });
    if (window.location.href.includes("/dashboard") || window.location.href.includes("/myprofile") || window.location.href.includes("/changepassword") || window.location.href.includes("/appreciation") || window.location.href.includes("/task")) {
      var elementsWithSubActive = document.querySelectorAll(".sub-active");
      elementsWithSubActive.forEach(function(element) {
        element.classList.remove("sub-active");
      });
      var globalActiveLinks = document.querySelectorAll(".active-link");
      globalActiveLinks.forEach(function(element) {
        element.classList.remove("active-link");
      });
    }
    const sidebarSubLinks = document.querySelectorAll('.sidebar ul li .sidebar-sub a, .sidebar ul li.home a, .sidebar ul li.support a, .sidebar ul li.logout a');
    const mblOverlay = document.querySelector('.mbl-overlay');
    sidebarSubLinks.forEach((link) => {
      link.addEventListener('click', function() {
        mblOverlay.click();
      });
    });
    document.getElementById("orgChartLink").onclick = function() {
      //window.open("http://183.82.120.94:6070/HRMS/OrgChart/OrgChart", "_blank");
      window.open(config.img_url + "HRMS/OrgChart/OrgChart", "_blank");
    };
    document.getElementById("hierarchyChartLink")?.addEventListener("click", function(event) {
      event.preventDefault();
      var dynamicBaseUrl = window.location.origin;
      var targetUrl = dynamicBaseUrl + "/#/hierarchychart";        
      window.location.href = targetUrl;
    });


  }
  async bindDashboard(empid){
    return await this.dashboardService.BindDynamicMenu(empid)
    .then(data => {
        this.dynamicmenu = data;
    });
  }
  onMenuClick() {
    event.preventDefault();
    const parentDiv = event.target.parentNode.parentNode;
    if(parentDiv.classList.contains('arrow')){
      parentDiv.classList.toggle('sub-active');
      const siblings = Array.from(parentDiv.parentElement.children);
      siblings.forEach(sibling => {
        if (sibling !== parentDiv) {
          sibling.classList.remove('sub-active');
        }
      });
    }
    else if(parentDiv.classList.contains('userlogout')){
      this.loginService.purgeAuth();
      if(this.service === true || this.service === 'true'){
        this.msalService.signOut();
      }else{
      this.app.updatePropertyValue(false);
      sessionStorage.removeItem('activeTab');
      this.router.navigateToRoute('login')
        window.location.reload();
    }
    }
    else{
      return false;
    }
  }
    toggleTeamFunctions() {
        this.showTeamFunctions = !this.showTeamFunctions; // Toggle the flag
    }

    onClick(filterremove) {
    const body = document.body;
    body.classList.remove('open-sidebar');
  }
  navigateToPage(support) {
    if (
          (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
          (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
        ) {
          setTimeout(function () {
            window.location.reload();
            //window.location.href = '/#/dashboard';
          }, 1000);
        } 
    this.router.navigateToRoute(support);
    this.getCurrentRouteURL();
  }

  getCurrentRouteURL() {
    const currentInstruction = this.router.currentInstruction;
    if (currentInstruction) {
      const routeURL = currentInstruction.fragment;
      this.dashboardService.GetRecentItems(this.employeeId)
    .then(data => {
        this.LoadRecentItems = data;
    });
      return routeURL;
    }
    return '';
  }
  
}

import {inject} from 'aurelia-framework';
import { Redirect } from 'aurelia-router';
import { JwtService } from '../shared/services/jwtservice';

@inject(JwtService)
export class AuthorizeStep {

  constructor(JwtService){
    this.jwtService = JwtService;
  }

  run(navigationInstruction, next) {
    if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) {
      var isLoggedIn = this.jwtService.getToken();
      if (!isLoggedIn) {
        return next.cancel(new Redirect('/'));
      }
    }

    return next();
  }
}

export const msalConfig = {
  auth: {
    clientId: 'bc3e385a-eb82-4cde-b4f9-3b8050758667',
    authority: 'https://login.microsoftonline.com/7504d69d-9f19-437d-9db0-d6c341c47dbb',
    redirectUri: 'https://hrms.kensium.com/#/dashboard', // Should match your app's configuration
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
};
